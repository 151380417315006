/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { useStaticQuery, graphql } from 'gatsby';
import { useRRBanner } from '../../hooks';
import { loadScripts, runAfterJquery } from '../../utils';
import PictureTag from '../Common/PictureTag';
import './RRPlacement.css';

let loaded = false;

const staticQuery = graphql`
  query RRQuery {
    allRrTag {
      nodes {
        attribs
        dataset
        html
      }
    }
    allChildTag {
      nodes {
        attribs
        dataset
        html
      }
    }
    allJQueryTag {
      nodes {
        attribs
        dataset
        html
      }
    }
    rrBanners {
      placements {
        DESTINATION_URL
        campaign
        placement
        loading
        sources {
          media
          localFile {
            id
            url
            childImageSharp {
              fluid(quality: 90) {
                aspectRatio
                sizes
                base64
              }
            }
          }
        }
      }
    }
  }
`;

export default function RRPlacement({
  attribs: { class: className, ...attribs },
  ...props
}) {
  const {
    allRrTag,
    allChildTag,
    allJQueryTag,
    rrBanners: { placements },
  } = useStaticQuery(staticQuery);
  const { campaign, loading, sources, DESTINATION_URL } = useRRBanner(
    attribs['data-rr-placement'],
    placements,
  );
  if (sources) {
    const [trackingUrl, href] = decodeURIComponent(DESTINATION_URL).split(
      'ct=',
    );
    return (
      <props.name>
        <div>
          <a
            href={href}
            alt={campaign}
            data-rr-click-tracking-url={trackingUrl}
            onClick={() => fetch(trackingUrl)}
          >
            <PictureTag loading={loading} sources={sources} />
          </a>
        </div>
      </props.name>
    );
  }
  const onEnter = () => {
    if (loaded || !Object.keys(placements).length) return;
    loaded = true;
    runAfterJquery(allJQueryTag, async () => {
      await loadScripts(allRrTag);
      loadScripts(allChildTag);
    });
  };
  return (
    <Waypoint onEnter={onEnter}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <props.name {...attribs} className={className} />
    </Waypoint>
  );
}
