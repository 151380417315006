import { useStaticQuery, graphql } from 'gatsby';

const useAllFile = () => {
  const { allFile } = useStaticQuery(graphql`
    query allFileQuery {
      allFile {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          url
        }
      }
    }
  `);
  return allFile;
};

export default useAllFile;
