import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import _keyBy from 'lodash/keyBy';
import { RRContext } from '../../hooks';
import '../../legacy-atg-css/global.css';
import '../../legacy-atg-css/style.css';
import '../../legacy-atg-css/accountPopup.css';
import '../../legacy-atg-css/responsive.css';
import '../../legacy-atg-css/nbtyStore.css';
import 'slick-carousel/slick/slick.css';
import '@HnBI/hnbicommon/styles/main.scss';
import './page.css';
import { loadScripts, runAfterJquery } from '../../utils';
import Header from '../Header';
import Footer from '../Footer';
import CategoryTracking from '../CategoryTracking';

const { SESSION_URL = '/api/getsession?path=/' } = process.env;

const staticQuery = graphql`
  query PageQuery {
    allScriptTag {
      nodes {
        attribs
        dataset
        html
      }
    }
    allJQueryTag {
      nodes {
        attribs
        dataset
        html
      }
    }
    allLazyTag {
      nodes {
        attribs
        dataset
        html
      }
    }
    allUTag {
      nodes {
        attribs
        dataset
        html
      }
    }
  }
`;

let isRRInitialised = false;

const useRRApi = () => {
  const [{ placements = [] }, setRR] = useState({});
  return {
    placements: _keyBy(placements, 'placement'),
    initRR: async () => {
      if (!isRRInitialised) {
        isRRInitialised = true;
        const response = await fetch(
          '/api/getpersonalisebanners?placements=home_page.banner1%7Chome_page.banner3%7Chome_page.banner4',
          { credentials: 'same-origin' },
        );
        setRR(await response.json());
      }
    },
  };
};

export default function Page({ searchHidden, children }) {
  const { allScriptTag, allJQueryTag, allLazyTag, allUTag } = useStaticQuery(
    staticQuery,
  );
  const [{ header, footer }, setSession] = useState({});
  const fetchSession = async () => {
    const response = await fetch(SESSION_URL, { credentials: 'include' });
    const { rrSession, ...json } = await response.json();
    Object.assign(window.RR_CONFIG, rrSession);
    setSession(json);
  };
  useEffect(() => {
    if (!window.RUN_AFTER_JQUERY) {
      // NOTE: this block would be executed only running the app locally
      loadScripts(allScriptTag);
    }
    runAfterJquery(allJQueryTag, () => loadScripts(allUTag));
    loadScripts(allLazyTag);
    fetchSession();
  }, [allScriptTag, allJQueryTag, allUTag, allLazyTag]);
  return (
    <>
      <CategoryTracking />
      <Header searchHidden={searchHidden} header={header} />
      <RRContext.Provider value={useRRApi()}>{children}</RRContext.Provider>
      <Footer footer={footer} />
    </>
  );
}
