/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Area } from 'react-magnolia';
import Slider from 'react-slick';
import useAllFile from '../../hooks/use-all-file';
import PictureTag from '../Common/PictureTag';
import { Title } from '../Common';
import styles from './Article.module.css';

const ArticleItem = props => {
  const { link, image, largeFormat, title, text } = props;
  let classNames = styles.article;

  if (largeFormat === 'true') classNames += ` ${styles.articleLargeFormat}`;

  const { nodes } = useAllFile();
  const localFile = nodes.find(n => n.url === image);
  const sources = [{ localFile }];

  return (
    <div className={classNames}>
      <a href={link}>
        <PictureTag
          sources={sources}
          alt={title}
          className={styles.articleImg}
        />
        <div className={styles.articleBody}>
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
      </a>
    </div>
  );
};

const ArticleItemMock = props => {
  const { link, image, largeFormat, title, text } = props;
  let classNames = styles.article;

  if (largeFormat === 'true') classNames += ` ${styles.articleLargeFormat}`;

  return (
    <div className={classNames}>
      <a href={link}>
        <img src={image} alt={title} />
        <div className={styles.articleBody}>
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
      </a>
    </div>
  );
};

const ArticleArea = props => {
  const { title, articles } = props;
  const renderedArticles = articles['@nodes'].map(node => {
    const { link, image, title, text, largeFormat } = articles[node];
    const key = articles[node]['@id'];

    return (
      <ArticleItem
        link={link}
        image={image}
        title={title}
        text={text}
        largeFormat={largeFormat}
        key={key}
      />
    );
  });

  return (
    <section className={styles.root}>
      <Title content={title} />
      {articles && <Area {...articles} className={styles.container} />}
      {renderedArticles && (
        <Slider
          dots
          arrows={false}
          infinite
          autoplay
          className={styles.slider}
          slidesToShow={1}
          slidesToScroll={1}
          autoplaySpeed={4000}
          dotsClass={`${styles.dots} slick-dots`}
        >
          {renderedArticles}
        </Slider>
      )}
    </section>
  );
};

const ArticleAreaMock = ({ title, articles }) => (
  <section className={styles.root}>
    <Title content={title} />
    {articles && <Area {...articles} className={styles.container} />}
  </section>
);

export { ArticleArea, ArticleItem, ArticleAreaMock, ArticleItemMock };
