/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Area } from 'react-magnolia';
// import { Image } from '../Common/Image';
import styles from './Usp.module.css';

const UspItem = ({ title, description, link, icon }) => (
  <div className={styles.uspitem}>
    <a href={link} id="usp1" className={styles.cta}>
      <img src={icon} alt={title} className={styles.icon} />
      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.desc}>{description}</p>
      </div>
    </a>
  </div>
);

const UspArea = ({ items, annotation }) => (
  <section className={styles.root}>
    {items && <Area {...items} className={styles.container} />}
    {annotation && <span className={styles.annotation}>{annotation}</span>}
  </section>
);

export { UspArea, UspItem };
