/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Area } from 'react-magnolia';
import { Title } from '../Common';
import styles from './Category.module.css';

const CategoryItem = ({ color, link, image, title }) => (
  <div className={styles.category}>
    <a href={link} style={{ backgroundColor: `#${color}` }}>
      <img className="lazyloaded" data-src={image} src={image} alt={title} />
      <h3>{title}</h3>
    </a>
  </div>
);

const CategoryArea = ({ categories, title }) => (
  <section className={styles.root}>
    <Title content={title} />
    {categories && <Area {...categories} className={styles.container} />}
  </section>
);

export { CategoryArea, CategoryItem };
