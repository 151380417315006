import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Footer as CommonFooter } from '@HnBI/hnbicommon';
import './Footer.css';

const staticQuery = graphql`
  query FooterQuery {
    atgScrapper {
      footer {
        footerCopyright
        footerNavigation {
          links {
            href
            text
          }
          title
        }
        footerPreFooter {
          additionalLinks {
            href
            text
          }
          href
          src
          text
          title
        }
        footerSignUpForm {
          fields {
            email {
              maxLength
              placeholder
            }
            firstName {
              maxLength
              placeholder
            }
            lastName {
              maxLength
              placeholder
            }
          }
          hiddenInputs {
            _DARGS
            _D_EmailAddress
            _D_FirstName
            _D_LastName
            _D__com_nbty_commerce_session_Honeypot_key
            _D__com_nbty_commerce_session_Honeypot_value
            _com_nbty_commerce_session_Honeypot_key
            _com_nbty_commerce_session_Honeypot_value
            _dyncharset
            signUp
          }
          title
          url
          privacyPolicy
        }
        footerVerisign {
          href
          alt
          src
        }
        social {
          options {
            children {
              href
              icon
              title
            }
          }
          type
        }
      }
    }
  }
`;

const Footer = ({ footer = {} }) => {
  const { atgScrapper } = useStaticQuery(staticQuery);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CommonFooter {...atgScrapper.footer} {...footer} />;
};

export default Footer;
