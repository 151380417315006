import { useEffect, createContext, useContext } from 'react';

const RRContext = createContext();

const useRRContext = rrBannerKey => {
  const { placements, initRR } = useContext(RRContext);
  useEffect(() => {
    initRR();
  }, [initRR]);
  const placementData = placements[rrBannerKey];
  if (!placementData) return null;
  const {
    creatives: [creative],
  } = placementData;
  return creative;
};

const useRRBanner = (rrBannerKey, ssrBanners) => {
  const personalisedBanner = useRRContext(rrBannerKey);
  const ssrBanner = ssrBanners.find(
    ({ placement }) => placement === rrBannerKey,
  );
  if (!ssrBanner) return {};
  if (!personalisedBanner) return ssrBanner;
  const { IMAGE_URL, MOBILE_IMAGE_URL, DESTINATION_URL } = personalisedBanner;
  const [ssrSouce, ssrMobileSource] = ssrBanner.sources;
  ssrSouce.localFile.childImageSharp.fluid.srcSet = IMAGE_URL;
  if (ssrMobileSource) {
    ssrMobileSource.localFile.childImageSharp.fluid.srcSet = MOBILE_IMAGE_URL;
  }
  return { ...ssrBanner, DESTINATION_URL };
};

export { RRContext, useRRBanner };
