/* eslint-disable react/no-danger */
import React from 'react';
import Title from './Title';
import styles from './RichText.module.css';

const RichText = ({ title, content }) => (
  <div className={styles.root}>
    {title && <Title content={title} />}
    <div
      className={styles.content}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  </div>
);

export default RichText;
