/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { useStaticQuery, graphql } from 'gatsby';
import { loadScripts, runAfterJquery } from '../../utils';
import styles from './ProductTeaser.module.css';

let loaded = false;

const staticQuery = graphql`
  query ProductTeaserQuery {
    allRrTag {
      nodes {
        attribs
        dataset
        html
      }
    }
    allChildTag {
      nodes {
        attribs
        dataset
        html
      }
    }
    allJQueryTag {
      nodes {
        attribs
        dataset
        html
      }
    }
  }
`;

const ProductTeaser = ({ placementId }) => {
  const { allRrTag, allChildTag, allJQueryTag } = useStaticQuery(staticQuery);

  const onEnter = () => {
    if (loaded) return;
    runAfterJquery(allJQueryTag, async () => {
      await loadScripts(allRrTag);
      loadScripts(allChildTag);
      loaded = true;
    });
  };

  return (
    <Waypoint onEnter={onEnter}>
      <div className={styles.root} data-rr-placement={placementId} />
    </Waypoint>
  );
};

const ProductTeaserMock = ({ name, placementId }) => (
  <div className={styles.root}>
    <div className={styles.mock}>
      <h4>PLACEHOLDER</h4>
      <h2>{name}</h2>
      <p>{placementId}</p>
    </div>
  </div>
);

export { ProductTeaser, ProductTeaserMock };
