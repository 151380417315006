/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Area } from 'react-magnolia';
import useAllFile from '../../hooks/use-all-file';
import { PictureTag, Title } from '../Common';
import styles from './TeaserImage.module.css';

const TeaserImageItem = ({ title, image, cta }) => {
  const { nodes } = useAllFile();
  const localFile = nodes.find(n => n.url === image);
  const sources = [{ localFile }];

  return (
    <div className={styles.teaser} key={title}>
      <a href={cta}>
        <PictureTag
          sources={sources}
          alt={title}
          className={styles.teaserImg}
        />
        <div className={styles.teaserLabel}>{title}</div>
      </a>
    </div>
  );
};

const TeaserImageItemMock = ({ title, image, cta }) => (
  <div className={styles.teaser} key={title}>
    <a href={cta}>
      <img src={image} alt={title} className={styles.teaserImg} />
      <div className={styles.teaserLabel}>{title}</div>
    </a>
  </div>
);

const TeaserImageArea = ({ title, items }) => (
  <section className={styles.root}>
    {title && <Title content={title} />}
    {items && <Area {...items} className={styles.container} />}
  </section>
);

export { TeaserImageArea, TeaserImageItem, TeaserImageItemMock };
