import React from 'react';
import useAllFile from '../../hooks/use-all-file';
import PictureTag from './PictureTag';
import styles from './Image.module.css';

const Image = ({ image1, image2, name }) => {
  const { nodes } = useAllFile();
  const desktopImage = nodes.find(n => n.url === image1);
  const mobileImage = nodes.find(n => n.url === image2);
  const sources = [
    {
      media: '(min-width: 768px)',
      localFile: desktopImage,
    },
  ];

  if (mobileImage)
    sources.push({
      media: '(max-width: 767px)',
      localFile: mobileImage,
    });

  return (
    <div className={styles.root}>
      {sources.length && <PictureTag sources={sources} alt={name} />}
    </div>
  );
};

const ImageMock = ({ image1, name }) => (
  <div className={styles.root}>
    <img src={image1} alt={name} />
  </div>
);

export { Image, ImageMock };
