/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { Area } from 'react-magnolia';
import useAllFile from '../../hooks/use-all-file';
import { Title } from '../Common';
import styles from './CustomerReview.module.css';

const CustomerReviewItem = ({ quote, author, rating }) => (
  <div className={styles.review} key={author}>
    <div className={styles.rating}>
      <div style={{ width: `${rating * 20}%` }} />
    </div>
    <p className={styles.quote}>{quote}</p>
    <p className={styles.author}>{author}</p>
  </div>
);

const CustomerReviewArea = ({ title, reviews, image1, image2 }) => {
  const { nodes } = useAllFile();
  const desktopImage = nodes.find(n => n.url === image1);
  const mobileImage = nodes.find(n => n.url === image2);
  const sources = [desktopImage.childImageSharp.fluid];

  if (mobileImage) {
    sources.push({
      ...mobileImage.childImageSharp.fluid,
      media: '(max-width: 767px)',
    });
  }

  return (
    <BackgroundImage tag="section" fluid={sources} className={styles.root}>
      <Title content={title} />
      {reviews && <Area {...reviews} className={styles.container} />}
    </BackgroundImage>
  );
};

const CustomerReviewAreaMock = ({ title, reviews, image1 }) => (
  <section
    className={styles.root}
    style={{ background: `url(${image1}) #e9e6de 50% 0 no-repeat` }}
  >
    <Title content={title} />
    {reviews && <Area {...reviews} className={styles.container} />}
  </section>
);

export { CustomerReviewArea, CustomerReviewAreaMock, CustomerReviewItem };
