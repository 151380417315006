export const loadScripts = ({ nodes }) => {
  const hasSiteSearch = window.HAB && window.HAB.hookSiteSearch;
  return Promise.all(
    nodes.map(
      ({ attribs, dataset, html }) =>
        new Promise((resolve, reject) => {
          const attr = JSON.parse(attribs);
          const script = document.createElement('script');
          Object.assign(script, attr);
          Object.assign(script.dataset, JSON.parse(dataset));
          script.onload = resolve;
          script.onerror = reject;
          script.defer = true;
          script.innerHTML = html;
          document.body.appendChild(script);
        }),
    ),
  ).then(
    () =>
      hasSiteSearch ||
      !window.HAB ||
      !window.HAB.hookSiteSearch ||
      window.HAB.hookSiteSearch(),
  );
};

export const runAfterJquery = async (allJQueryTag, callback) => {
  if (window.$) return callback();
  if (!window.RUN_AFTER_JQUERY) {
    // NOTE: this block would be executed only running the app locally
    await loadScripts(allJQueryTag);
    return callback();
  }
  const { RUN_AFTER_JQUERY } = window;
  window.RUN_AFTER_JQUERY = () => {
    RUN_AFTER_JQUERY();
    callback();
  };
};
