import React from 'react';
import styles from './Ratings.module.css';

const Ratings = () => <div id="BVSellerRatingsContainer" />;

const RatingsMock = ({ name }) => (
  <div className={styles.root}>
    <div className={styles.mock}>
      <h4>PLACEHOLDER</h4>
      <h2>{name}</h2>
    </div>
  </div>
);

export { Ratings, RatingsMock };
