/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Area } from 'react-magnolia';
import BackgroundImage from 'gatsby-background-image';
import useAllFile from '../../hooks/use-all-file';
import PictureTag from '../Common/PictureTag';
import { Title } from '../Common';
import styles from './HowToSave.module.css';

const HowToSaveItem = ({ quantity, saving }) => (
  <div className={styles.saveItem}>
    <h2>
      {quantity}
      <small>per order</small>
    </h2>
    <p>
      Save
      <strong>{saving}</strong>
    </p>
  </div>
);

const SaveBenefits = ({ benefits, footnote, baseImage, title }) => {
  const { nodes } = useAllFile();
  const localFile = nodes.find(n => n.url === baseImage);
  const sources = [{ localFile }];

  return (
    <div className={styles.saveBenefits}>
      <div className={styles.benefits}>
        {benefits &&
          benefits.map((benefit, index) => (
            <p key={`benefit${index}`}>{benefit}</p>
          ))}
      </div>
      <p>
        <strong>{footnote}</strong>
      </p>
      <div className={styles.guarantee}>
        <PictureTag
          sources={sources}
          alt={title}
          className={styles.guaranteeImg}
        />
      </div>
    </div>
  );
};

const SaveBenefitsMock = ({ benefits, footnote, baseImage, title }) => (
  <div className={styles.saveBenefits}>
    <div className={styles.benefits}>
      {benefits &&
        benefits.map((benefit, index) => (
          <p key={`benefit${index}`}>{benefit}</p>
        ))}
    </div>
    <p>
      <strong>{footnote}</strong>
    </p>
    <div className={styles.guarantee}>
      <img src={baseImage} alt={title} className={styles.guaranteeImg} />
    </div>
  </div>
);

const HowToSaveArea = ({
  title,
  image,
  benefits,
  footnote,
  baseImage,
  items,
}) => {
  const { nodes } = useAllFile();
  const mainImage = nodes.find(n => n.url === image);
  const sources = [mainImage.childImageSharp.fluid];

  return (
    <BackgroundImage tag="section" fluid={sources} className={styles.root}>
      <Title content={title} />
      {items && <Area {...items} className={styles.container} />}
      <SaveBenefits
        benefits={benefits}
        footnote={footnote}
        title={title}
        baseImage={baseImage}
      />
    </BackgroundImage>
  );
};

const HowToSaveAreaMock = ({
  title,
  image,
  benefits,
  footnote,
  baseImage,
  items,
}) => (
  <section className={styles.root} style={{ backgroundImage: `url(${image})` }}>
    <Title content={title} />
    {items && <Area {...items} className={styles.container} />}
    <SaveBenefitsMock
      benefits={benefits}
      footnote={footnote}
      title={title}
      baseImage={baseImage}
    />
  </section>
);

export { HowToSaveArea, HowToSaveAreaMock, HowToSaveItem };
