/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Header as CommonHeader } from '@HnBI/hnbicommon';
import './Header.css';

const staticQuery = graphql`
  query HeaderQuery {
    atgScrapper {
      header {
        basketPrice
        basketTotal
        favouritesCount
        headerCurrency {
          formAction
          hiddenInputs {
            _DARGS
            _D__com_nbty_commerce_common_CurrencyConverterFormHandler_changeCurrency
            _D__com_nbty_commerce_common_CurrencyConverterFormHandler_currencyConverterErrorURL
            _D__com_nbty_commerce_common_CurrencyConverterFormHandler_currencyConverterSuccessURL
            _D_existingCurrencyType
            _D_selectedCurrencyType
            _com_nbty_commerce_common_CurrencyConverterFormHandler_changeCurrency
            _com_nbty_commerce_common_CurrencyConverterFormHandler_currencyConverterErrorURL
            _com_nbty_commerce_common_CurrencyConverterFormHandler_currencyConverterSuccessURL
            _dynSessConf
            _dyncharset
            existingCurrencyType
            selectedCurrencyType
          }
          selectedCurrency
        }
        headerLinks {
          accountLink
          cartLink
          storeLink
        }
        headerTopLinks {
          rows {
            links {
              dropdown {
                href
                isLogout
                text
              }
              href
              key
              text
            }
          }
        }
        loggedIn
        menu {
          children {
            children {
              icon
              path
              title
            }
            options {
              id
            }
            path
            title
          }
          options {
            id
          }
          path
          title
          type
        }
        menuFooter {
          accordionMenuContent {
            options {
              children {
                href
                title
              }
            }
            title
            type
          }
          accountMenuContent {
            options {
              icon
            }
            title
            path
            type
          }
          socialMenuContent {
            options {
              children {
                href
                icon
                title
              }
            }
            type
          }
        }
        navigation {
          path
          children {
            banner {
              href
              src
            }
            children {
              children {
                path
                title
              }
              options {
                id
              }
              path
              title
            }
            desktop {
              background
              flyoutBlocks {
                blockTitle {
                  href
                  text
                }
                href
                children {
                  href
                  text
                }
                src
              }
            }
            options {
              class
              id
            }
            path
            title
            type
          }
          title
        }
        newHeaderLinks {
          accountLink
          cartLink
          header_fav_link {
            href
            key
            text
          }
          header_myacc_link {
            href
            key
            text
          }
          header_rewards_link {
            dropdown {
              href
              isLogout
              text
            }
            href
            key
            text
          }
          header_storeloc_link {
            href
            key
            text
          }
          storeLink
        }
        promoBanner
      }
    }
  }
`;

const useMiniBasket = () => {
  const [minibasket, setMiniBasket] = useState({});
  const fetchMiniBasket = async () => {
    const response = await fetch('/api/getminibasket', {
      credentials: 'include',
    });
    setMiniBasket(await response.json());
  };
  useEffect(() => {
    fetchMiniBasket();
  }, []);
  return minibasket;
};

const Header = ({ searchHidden, header = {} }) => {
  const { atgScrapper } = useStaticQuery(staticQuery);
  const { headerLinks = {} } = header;
  const { miniBasket } = useMiniBasket();
  return (
    <CommonHeader
      {...atgScrapper.header}
      {...header}
      headerLinks={{ ...atgScrapper.header.headerLinks, ...headerLinks }}
      searchHidden={searchHidden}
      miniBasket={miniBasket}
      menu={[
        ...atgScrapper.header.menu,
        {
          children: [],
          options: { id: 'Account' },
          path: headerLinks.accountLink,
          title: header.loggedIn ? 'Log out' : 'Account',
          isLogout: header.loggedIn,
          form: header.headerAccount,
          type: 'MainNavItem',
        },
      ]}
    />
  );
};

export default Header;
