import React from 'react';
import { Link } from 'gatsby';
import styles from './Breadcrumbs.module.css';

const Breadcrumbs = ({ crumbs }) => (
  <ul className={styles.crumbs}>
    <li>
      <Link to="/">Home</Link>
    </li>
    {crumbs &&
      crumbs.map(({ name, path }, index) => (
        <li key={path}>
          {index === crumbs.length - 1 ? name : <Link to={path}>{name}</Link>}
        </li>
      ))}
  </ul>
);

export default Breadcrumbs;
