import AuthorPage from './src/templates/author';
import {
  BannerArea,
  BannerItem,
  BannerItemMock,
  BannerRR,
  BannerRRMock,
  Image,
  ImageMock,
  RichText,
} from './src/components/Common';
import {
  ArticleArea,
  ArticleItem,
  ArticleAreaMock,
  ArticleItemMock,
  CategoryArea,
  CategoryItem,
  ProductTeaser,
  ProductTeaserMock,
  Ratings,
  RatingsMock,
  UspArea,
  UspItem,
} from './src/components/Home';
import {
  AsEasyAs,
  CustomerReviewArea,
  CustomerReviewAreaMock,
  CustomerReviewItem,
  FAQs,
  HowToSaveArea,
  HowToSaveAreaMock,
  HowToSaveItem,
  TeaserImageArea,
  TeaserImageItem,
  TeaserImageItemMock,
} from './src/components/Subscribe';

const isMagnolia = !!process.env.PUBLIC_URL;

const config = {
  templates: {
    'attract:pages/Home': AuthorPage,
    'attract:pages/Subscribe': AuthorPage,
    'attract:areas/Article': isMagnolia ? ArticleAreaMock : ArticleArea,
    'attract:components/Article': isMagnolia ? ArticleItemMock : ArticleItem,
    'attract:components/AsEasyAs': AsEasyAs,
    'attract:areas/Banner': BannerArea,
    'attract:components/BannerRR': isMagnolia ? BannerRRMock : BannerRR,
    'attract:components/Banner': isMagnolia ? BannerItemMock : BannerItem,
    'attract:areas/Category': CategoryArea,
    'attract:components/Category': CategoryItem,
    'attract:areas/CustomerReview': isMagnolia
      ? CustomerReviewAreaMock
      : CustomerReviewArea,
    'attract:components/CustomerReview': CustomerReviewItem,
    'attract:components/FAQs': FAQs,
    'attract:areas/HowToSave': isMagnolia ? HowToSaveAreaMock : HowToSaveArea,
    'attract:components/HowToSave': HowToSaveItem,
    'attract:components/Image': isMagnolia ? ImageMock : Image,
    'attract:components/ProductTeaser': isMagnolia
      ? ProductTeaserMock
      : ProductTeaser,
    'attract:components/Ratings': isMagnolia ? RatingsMock : Ratings,
    'attract:components/RichText': RichText,
    'attract:areas/TeaserImage': TeaserImageArea,
    'attract:components/TeaserImage': isMagnolia
      ? TeaserImageItemMock
      : TeaserImageItem,
    'attract:areas/Usp': UspArea,
    'attract:components/Usp': UspItem,
  },
};

export default config;
