export default class Router {
  static push({ pathname, query }) {
    window.location.href =
      window.location.origin +
      pathname +
      Object.entries(query)
        .map(([k, v]) => `${k}=${v}`)
        .join('&');
  }
}
