import React from 'react';
import { Title } from '../Common';
import styles from './AsEasyAs.module.css';

const StepItem = ({ step, title, text }) => (
  <div className={styles.step}>
    <div className={styles.stepNumber}>{step}</div>
    <div className={styles.stepBody}>
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  </div>
);

const AsEasyAs = props => {
  const {
    title,
    step1name,
    step1text,
    step2name,
    step2text,
    step3name,
    step3text,
  } = props;

  return (
    <div className={styles.root}>
      <Title content={title} />
      <div className={styles.container}>
        <StepItem step="1" title={step1name} text={step1text} />
        <StepItem step="2" title={step2name} text={step2text} />
        <StepItem step="3" title={step3name} text={step3text} />
      </div>
    </div>
  );
};

export default AsEasyAs;
