import React from 'react';
import { RichText } from '../Common';
import styles from './FAQs.module.css';

const FAQs = ({ title, faqs, cta }) => (
  <div className={styles.root}>
    <RichText title={title} content={faqs} />
    <a href={cta} className={styles.cta}>
      Read all FAQs
    </a>
  </div>
);

export default FAQs;
