import React, { useEffect } from 'react';

export default function CarouselTracking() {
  const addOnClick = () => {
    const categories = document.querySelectorAll('.category-cartridge-item');
    categories.forEach(el => {
      const h2 = el.getElementsByTagName('h2')[0].innerText;
      el.onclick = () => {
        window.SS.EventTrack.rp(`${h2.replace(/\s/g, '_')}-category-link`);
      };
    });
  };
  const removeOnClick = () => {
    const categories = document.querySelectorAll('.category-cartridge-item');
    categories.forEach(el => {
      el.removeAttribute('onclick');
    });
  };
  useEffect(() => {
    addOnClick();
    return () => {
      removeOnClick();
    };
  }, []);
  return <div />;
}
