/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Area } from 'react-magnolia';
import { useStaticQuery, graphql } from 'gatsby';
import { useAllFile, useRRBanner } from '../../hooks';
import PictureTag from './PictureTag';
import Title from './Title';
import styles from './Banner.module.css';

const staticRrBannersQuery = graphql`
  query rrBannersQuery {
    rrBanners {
      placements {
        DESTINATION_URL
        campaign
        placement
        loading
        sources {
          media
          localFile {
            id
            childImageSharp {
              fluid {
                aspectRatio
                sizes
                base64
              }
            }
          }
        }
      }
    }
  }
`;

const BannerArea = ({ title, banners }) => (
  <section className={styles.root}>
    {title && <Title content={title} />}
    {banners && <Area {...banners} className={styles.container} />}
  </section>
);

const BannerItem = ({ image1, image2, name, link }) => {
  const { nodes } = useAllFile();
  const desktopImage = nodes.find(n => n.url === image1);
  const mobileImage = nodes.find(n => n.url === image2);
  const sources = [
    {
      media: '(min-width: 768px)',
      localFile: desktopImage,
    },
  ];

  if (mobileImage)
    sources.push({
      media: '(max-width: 767px)',
      localFile: mobileImage,
    });

  return (
    <div className={styles.banner}>
      <a href={link}>
        {sources.length && <PictureTag sources={sources} alt={name} />}
      </a>
    </div>
  );
};

const BannerRR = ({ placementId }) => {
  const {
    rrBanners: { placements },
  } = useStaticQuery(staticRrBannersQuery);

  const { campaign, loading, sources, DESTINATION_URL } = useRRBanner(
    placementId,
    placements,
  );

  const [trackingUrl, href] = decodeURIComponent(DESTINATION_URL).split('ct=');
  const onClickHandler = () => fetch(trackingUrl);

  return (
    <div className={styles.banner}>
      <a
        href={href}
        alt={campaign}
        data-rr-click-tracking-url={trackingUrl}
        onClick={onClickHandler}
      >
        {sources && (
          <PictureTag loading={loading} sources={sources} alt={campaign} />
        )}
      </a>
    </div>
  );
};

const BannerItemMock = ({ image1, name, link }) => (
  <div className={styles.banner}>
    <a href={link}>
      <img src={image1} alt={name} />
    </a>
  </div>
);
const BannerRRMock = ({ name, placementId }) => (
  <div className={styles.banner}>
    <div className={styles.mock}>
      <h4>PLACEHOLDER</h4>
      <h2>{name}</h2>
      <p>{placementId}</p>
    </div>
  </div>
);

export { BannerArea, BannerItem, BannerItemMock, BannerRR, BannerRRMock };
