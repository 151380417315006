/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Img from 'gatsby-image';

const mediaStyle = (media, className, aspectRatio) => `
@media screen and ${media} {
  .${className} {
    height: ${100 / aspectRatio}vw;
  }
}`;

const MediaStyle = ({ sources, className }) => {
  const source = sources.find(
    ({ media, localFile }) => localFile != null && media.includes('max-width'),
  );
  if (!source) return '';
  const { aspectRatio } = source.localFile.childImageSharp.fluid;
  return (
    aspectRatio && (
      <style>{mediaStyle(source.media, className, aspectRatio)}</style>
    )
  );
};

const getFluid = sources => {
  if (sources.length > 1) {
    return sources.map(({ media, base64, localFile }) =>
      localFile == null
        ? { media, srcSet: base64 }
        : { ...localFile.childImageSharp.fluid, media },
    );
  }
  const [{ localFile }] = sources;
  return localFile.childImageSharp.fluid;
};

export default function PictureTag({
  id,
  sources,
  className,
  loading,
  ...props
}) {
  const [{ localFile = {} }] = sources;
  const { childImageSharp = {} } = localFile;
  const { fixed } = childImageSharp;
  const fluid = getFluid(sources);
  const mediaClass = className || `_${id || localFile.id}`;
  return (
    <>
      {!className && sources.length > 1 && (
        <MediaStyle sources={sources} className={mediaClass} />
      )}
      <Img
        {...props}
        className={mediaClass}
        {...(loading != null && { loading })}
        {...(fixed != null && { fixed })}
        {...(fluid != null && { fluid })}
      />
    </>
  );
}
